import React from 'react'
import "../Footer/Footer.css";

const Footer = () => {
  return (
    <footer id='fot' >
    <p>© 2023 Portfolio created by Tomas Ninno. All rights reserved.</p>
  </footer>
  )
}

export default Footer